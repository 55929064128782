var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:'panel ' + (_vm.viewer ? 'panel-info' : 'panel-primary')},[_c('div',{staticClass:"panel-body"},[_c('form',{attrs:{"action":""},on:{"submit":function($event){$event.preventDefault();return _vm.buscarExpediente.apply(null, arguments)}}},[_c('div',{staticClass:"col-xs-2 col-sm-2 col-lg-2"},[_vm._v("Expediente")]),_vm._v(" "),_c('div',{staticClass:"col-xs-2 col-sm-2 col-lg-2 text-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.expediente.iddocumentomain),expression:"expediente.iddocumentomain"}],staticClass:"form-control text-center",attrs:{"type":"text","placeholder":"N°","name":"exma_id","disabled":_vm.$route.name === 'DocuResponse'},domProps:{"value":(_vm.expediente.iddocumentomain)},on:{"change":_vm.buscarExpediente,"input":function($event){if($event.target.composing)return;_vm.$set(_vm.expediente, "iddocumentomain", $event.target.value)}}})]),_vm._v(" "),_c('div',{staticClass:"col-xs-8 col-sm-8 col-lg-8"},[(_vm.expediente.created_at != null)?_c('div',[_vm._v("expediente creado el "+_vm._s(_vm.expediente.created_at))]):_vm._e(),_vm._v(" "),(_vm.expediente.doc.length >0)?_c('div',[_c('strong',[_vm._v(_vm._s(_vm.getTipoDocumentoNombre(_vm.expediente.doc[0].docu_idtipodocumento) +
              ' ' +
              (_vm.expediente.doc[0].docu_numero_doc != null
                ? ('000000' + _vm.expediente.doc[0].docu_numero_doc).slice(-6)
                : '____') +
              ' ' +
              _vm.expediente.doc[0].docu_siglas_doc))]),_vm._v("\n          -> "+_vm._s(_vm.expediente.doc[0].docu_asunto)+"\n        ")]):_vm._e()])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }