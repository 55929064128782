import axios from 'axios';

const instance = axios.create();

instance.interceptors.response.use(function (response) {return response;}, function (error) {
  if(error.response.status===401)
    // eslint-disable-next-line no-undef
    $('#login').modal({backdrop: 'static', keyboard: false})
  return Promise.reject(error);
});


export default instance