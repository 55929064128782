var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"modal-header"},[_c('button',{staticClass:"close",attrs:{"type":"button","data-dismiss":"modal"}},[_vm._v("×")]),_vm._v(" "),_c('h4',{staticClass:"modal-title"},[_c('strong',[_vm._v("Expediente: ")]),_vm._v(_vm._s(_vm.formData.iddocumento))]),_vm._v(" "),_vm._m(0)]),_vm._v(" "),_c('div',{staticClass:"modal-body"},[_c('div',{staticClass:"panel panel-primary"},[_c('div',{staticClass:"panel-body"},[_c('table',{staticClass:"table table-bordered table-condensed table-hover"},[_vm._m(1),_vm._v(" "),_c('tbody',_vm._l((_vm.expediente),function(exp,index){return _c('tr',{key:index},[_c('td',[_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.recargar(exp.iddocumento)}}},[_vm._v(_vm._s(('00000000' + exp.iddocumento).slice(-8)))])]),_vm._v(" "),_c('td',[_vm._v(_vm._s(exp.docu_fecha_doc))]),_vm._v(" "),_c('td',[_vm._v("\n                "+_vm._s(exp.tdoc_descripcion +
                    ' ' +
                    ('000000' + exp.docu_numero_doc).slice(-6) +
                    (exp.docu_origen == 1 ? '-' + exp.docu_fecha_doc.substr(0, 4) : '') +
                    (exp.docu_siglas_doc != null ? '-' + exp.docu_siglas_doc : ''))+"\n              ")]),_vm._v(" "),_c('td',[_vm._v(_vm._s(exp.docu_asunto))]),_vm._v(" "),_c('td',[_vm._v(_vm._s(exp.docu_firma))]),_vm._v(" "),_c('td',[_vm._v(_vm._s(exp.depe_nombre))])])}),0)])])])]),_vm._v(" "),_c('div',{staticClass:"modal-footer"},[_c('button',{staticClass:"btn btn-primary",on:{"click":function($event){return _vm.imprimirExpediente()}}},[_vm._v("Imprimir")])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"modal-title"},[_c('strong',[_vm._v("Registros del expediente:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',{staticClass:"info"},[_c('th',{staticStyle:{"width":"7%"}},[_vm._v("Registro")]),_vm._v(" "),_c('th',{staticStyle:{"width":"8%"}},[_vm._v("Fecha")]),_vm._v(" "),_c('th',{staticStyle:{"width":"20%"}},[_vm._v("Documento")]),_vm._v(" "),_c('th',{staticStyle:{"width":"30%"}},[_vm._v("Asunto")]),_vm._v(" "),_c('th',{staticStyle:{"width":"15%"}},[_vm._v("Firma")]),_vm._v(" "),_c('th',{staticStyle:{"width":"30%"}},[_vm._v("Unidad Org.")])])])
}]

export { render, staticRenderFns }