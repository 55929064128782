//import auth from '../auth'
import axios from '@/js/plugins/client'

export default class File {

  constructor (file) {
    let defaultValues = {
      file                 : null,
      file_url             : null,
      md5                  : null,
      file_name            : null,
      file_tipo            : null,
      file_size            : null,
      file_mostrar         : true,
      file_principal       : null,
      file_generado        : null,
      id_documento         : null,
      id_documento_externo : null,
      id                   : null,
      file_pages           : 0,
      firma                : {trustedSignatures: false},
    }
    if(file!=undefined&&file!=null){
      defaultValues = Object.assign(defaultValues, {
        file      : file,
        file_name : file.name,
        file_tipo : file.type,
        file_size : file.size,
      })
    }
    this.file                 = defaultValues.file
    this.file_url             = defaultValues.file_url
    this.md5                  = defaultValues.md5
    this.file_name            = defaultValues.file_name
    this.file_tipo            = defaultValues.file_tipo
    this.file_size            = defaultValues.file_size
    this.file_mostrar         = defaultValues.file_mostrar
    this.file_principal       = defaultValues.file_principal
    this.file_generado        = defaultValues.file_generado
    this.id_documento         = defaultValues.id_documento
    this.id_documento_externo = defaultValues.id_documento_externo
    this.id                   = defaultValues.id
    this.file_pages           = defaultValues.file_pages
    this.firma                = defaultValues.firma
  }

  cargarFile (uploadProcess=function () {}) {
    let f = new FormData()
    f.append('file', this.file)
    return axios.post('/tramite/documento/upload', f, { headers: { 'Content-Type': 'multipart/form-data' },onUploadProgress: uploadProcess })
      .catch(() => {
        console.log('FAILURE!!')
      })
  }
  validarFile (route) {
    let params = {route}
    return axios.post('/tramite/documento/validar', params, )
  }

  getData () {
    return {
      file_url             : this.file_url,
      file_mostrar         : this.file_mostrar,
      file_principal       : this.file_principal,
      file_generado        : this.file_generado,
      file_name            : this.file_name,
      file_tipo            : this.file_tipo,
      file_size            : this.file_size,
      md5                  : this.md5,
      id_documento         : this.id_documento,
      id_documento_externo : this.id_documento_externo,
      id                   : this.id,
      file_pages           : this.file_pages,
      firma                : this.firma
    }
  }
}
